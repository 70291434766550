import React, { useState, useEffect } from "react";
import logo from "./logo.png";
import newPicture1 from "./scary1.png"; // Import the new image
import newPicture2 from "./scary2.png"; // Import the new image
import "./App.css";
import screamSound1 from "./scream1.mp3"; // Import the audio file
import screamSound2 from "./scream2.mp3"; // Import the audio file

function App() {
  const [showNewPicture, setShowNewPicture] = useState(false);
  const [currentPicture, setCurrentPicture] = useState(null);
  const [audioInterval, setAudioInterval] = useState(null);

  const handleClick = () => {
    setShowNewPicture(true);
    setCurrentPicture(1);
  };

  useEffect(() => {
    if (showNewPicture && currentPicture !== null) {
      const audio = new Audio(
        currentPicture === 1 ? screamSound2 : screamSound1
      );
      audio.play();

      const timer = setTimeout(() => {
        setCurrentPicture((prevPicture) => (prevPicture === 1 ? 2 : 1));
      }, 2000);

      // Clear previous interval if it exists
      if (audioInterval) {
        clearInterval(audioInterval);
      }

      // Set new interval for audio
      const interval = setInterval(() => {
        const audio = new Audio(
          currentPicture === 1 ? screamSound2 : screamSound1
        );
        audio.play();
      }, 2000);

      setAudioInterval(interval);

      return () => {
        clearTimeout(timer);
        clearInterval(interval);
      };
    }
  }, [showNewPicture, currentPicture]);

  return (
    <div className="App">
      {showNewPicture ? (
        <div className="full-screen-picture">
          <img
            src={currentPicture === 1 ? newPicture1 : newPicture2}
            alt="new-pic"
          />
        </div>
      ) : (
        <>
          <div
            style={{
              color: "yellow",
              fontSize: "40px",
              position: "fixed",
              top: "20px",
              left: "20px",
            }}
          >
            HAPPY MEAL
          </div>
          <header className="App-header">
            <img
              src={logo}
              className="App-logo"
              alt="happy-meal"
              onClick={handleClick}
            />
            <div
              className="clickable-div"
              style={{ color: "yellow", marginTop: "50px" }}
              onClick={handleClick}
            >
              Click Here
            </div>
            <div style={{ marginTop: "40px" }}>
              <a
                href="https://t.me/happymealonbase"
                target="_blank"
                rel="noopener noreferrer"
                className="clickable-div"
                style={{ color: "yellow", marginRight: "20px" }}
              >
                <i className="fab fa-telegram-plane"></i> Telegram
              </a>
              <a
                href="https://x.com/HappyMealOnBase"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "yellow" }}
                className="clickable-div"
              >
                <i className="fab fa-twitter"></i> Twitter
              </a>
            </div>
          </header>
        </>
      )}
    </div>
  );
}

export default App;
